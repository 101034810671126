import React, {useEffect, useState} from 'react'
import {
  Carousel,
  InstagramCard,
  IconLink,
  IconFacebook,
  IconTwitter,
  IconShare,
  InstagramAccount,
  useWindowDimensions,
} from '@Knowledge-OTP/znk-ui-components'
import InstagramBasicDisplayApi from 'instagram-basic-display'
import {showAlert} from '../../../../common/Alert/util'
import {useDispatch} from 'react-redux'
// const LIVEPREP_URL = `https://localhost:3002/home/`
const LIVEPREP_URL = `https://liveprep.zinkerz.com/`

const APP_ID = '5864863553631944'
const APP_SECRET = 'bb440358b15974dd866cce24833866a3'

const parseMedia = (media) => {
  return {
    ...media,
    instagram_link: media.permalink,
    label: media.caption,
    image_url:
      media.media_type === 'VIDEO' ? media.thumbnail_url : media.media_url,
  }
}

const parseMediaArray = (array = []) => {
  return array.map(parseMedia)
}

const filterMediaArray = (array = []) => {
  return array.filter(({media_type}) => ['IMAGE', 'VIDEO'].includes(media_type))
}

const InstagramWidget = () => {
  const dispatch = useDispatch()
  const {breakWidth} = useWindowDimensions()
  const [longToken] = useState(
    'IGQWRNMTgwOU9WZA2dBSE9YaThmUHJTTDl6LXBOaEtmWGR1QzMwY2ZAlM1pyNURTTHotV3lZAcmtkOEllTkJuVlN5Smx5ZA2llcEZA4aVdyNUZASRjhyNzFDd2JMbG16Yy1lX1NlYzBLSFYyLV9EWjFtTElfaHNwUVVYQUEZD'
  )

  // https://dev.liveprep.zinkerz.com/?code=AQCSViujQqywkRCPT0iIo7F8iaba1zgfxg-8s7O0NYUf5C05-fSdQpNCkJjJYt2EBKopUAybOdt4BdIGw3RJv__3nKeMXDweUuWBWBO-9GaWJLsiHpzmo6pv2_3t7dwXOkELY35GfpV7XUHhtqTt3Y7Ao1KHxOBzCYQ_XJXgTj_omDhjOO2sM8RHT3G0d2UAJnietWtIVo1YLqwweia-UI42dWIKhR9Qxx_db0oamFdYzw#_

  const ig = new InstagramBasicDisplayApi({
    appId: APP_ID,
    redirectUri: LIVEPREP_URL,
    appSecret: APP_SECRET,
  })

  const [mediaContent, setContent] = useState(null)
  const [userContent, setUserContent] = useState(null)
  useEffect(() => {
    // retrieveUserNode
    if (!mediaContent)
      ig.retrieveUserMedia(longToken, 30).then((data) => {
        setContent(parseMediaArray(filterMediaArray(data.data)))
      })
    if (!userContent)
      ig.retrieveUserNode(longToken).then((data) => {
        setUserContent(data)
      })
    // eslint-disable-next-line
    }, [longToken, mediaContent, userContent])

  const thirdOption =
    navigator?.share || navigator?.clipboard?.writeText
      ? [
          {
            Icon: navigator?.share ? IconShare : IconLink,
            onClick: (publ) => {
              if (navigator?.share)
                navigator
                  .share({
                    title: 'Share Instagram Zinkerz Post',
                    text: publ.label || 'No description.',
                    url: publ.instagram_link,
                  })
                  .then(
                    () => {},
                    (er) => {
                      console.error(er)
                    },
                  )
              else if (navigator?.clipboard?.writeText) {
                navigator.clipboard.writeText(publ.instagram_link).then(() => {
                  showAlert({text: 'Copy!', status: 'success'}, dispatch)
                }, console.error)
              }
            },
          },
        ]
      : []

  const [fakeLoading, setFakeLoading] = useState(true)
  useEffect(() => {
    setTimeout(() => {
      setFakeLoading(false)
    }, [1000 * 3])
  }, [])
  return (
    <div>
      <InstagramAccount
        loading={!userContent || fakeLoading}
        data={userContent}
      />
      <Carousel
        loading={!mediaContent || fakeLoading}
        itemsPerPage={breakWidth === 'SM' ? 1 : 3}
        delay={10 * 1000}
        ItemComponent={InstagramCard}
        autoScroll
        items={(mediaContent || []).map((publ, index) => {
          return {
            data: publ,
            index,
            className: 'm-1',
            onClick: () => {
              window.open(`${publ.instagram_link}`, '_blank')
            },
            icons: [
              {
                Icon: IconFacebook,
                onClick: () => {
                  window.open(
                    `https://www.facebook.com/sharer/sharer.php?u=${publ.instagram_link}`,
                    `_blank`,
                  )
                },
              },
              {
                Icon: IconTwitter,
                onClick: () => {
                  window.open(
                    `https://twitter.com/intent/tweet?text=${publ.label}`,
                    '_blank',
                  )
                },
              },
            ].concat(thirdOption),
          }
        })}
      />
    </div>
  )
}

export default InstagramWidget
